<template>
  <sub-page
      :title="$t('Tasks')"
      v-model="$store.state.settings.pageData"
  >

    <ws-data-table
        :items="items"
        :headers="headers"
        class="mt-5"
        dense
        no-footer
        disable-pagination
    >

      <template #item.select="{item}">
        <ws-check-box
            :value="item.completed"
        />
      </template>

      <template #item.name="{item}">
        <ws-text-field-new
            @click.prevent
            @change="editComponent('name' , $event, item.uuid , i)"
            v-model="item.name"
            :placeholder="$t('EnterName')"
            :color="wsACCENT"
            bold
            text-area
            full-width

        />

      </template>

      <template #item.status="{item}">
        <ft-select
            @input="editComponent('status' , $event, item.uuid , i)"
            :value="item.status"
            :items="statusSelect"
        >
          <template #default="{text}">
            <h5 class="text-no-wrap"> {{ text }}
              <v-icon>mdi-menu-down</v-icon>
            </h5>
          </template>

        </ft-select>

      </template>


      <template #item.date_created="{item}">
        <h5 :style="`color : ${wsACCENT}`" class="text-no-wrap" > {{ MONTH_DAY_TIME(item.date_created , false)  }}</h5>
      </template>

      <template #item.date_start="{item}">
        <ws-date-picker
            @input="editComponent('date_start' , $event, item.uuid , i)"
            :value="item.date_start"
            custom-activator
            time-stamp
            :key="item.uuid"
        >
          <h5 class="text-no-wrap" >
            {{ item.date_start ?  MONTH_DAY_TIME(item.date_start , false) : $t('DateStart')  }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h5>
        </ws-date-picker>
      </template>

      <template #item.date_end="{item}">
        <ws-date-picker
            @input="editComponent('date_end' , $event, item.uuid , i)"
            :value="item.date_end"
            custom-activator
            time-stamp
            :key="item.uuid"
        >
          <h5 class="text-no-wrap" >
            {{ item.date_end ?  MONTH_DAY_TIME(item.date_end , false) : $t('Deadline')  }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h5>
        </ws-date-picker>
      </template>

      <template #item.hover="{item,hover}">
        <v-sheet color="transparent" width="30">
          <v-btn @click="deleteItem(item.uuid, i)" small v-if="hover" icon :color="wsACCENT">
            <v-icon small>mdi-delete-outline</v-icon>
          </v-btn>
        </v-sheet>
      </template>


    </ws-data-table>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Tasks",
  data() {
    return {
      items : []
    }
  },
  computed : {
    headers() {
      return [
        { value : 'select'        , width : 10, padding : 0 , sortable : false},
        { text : this.$t('Name')  , value : 'name' , padding : 0 },
        { text : this.$t('Status')  , value : 'status' , padding : 0 , width : 100 },
        { text : this.$t('Start')  , value : 'date_start' ,   width : 100 },
        { text : this.$t('Deadline')  , value : 'date_end' , width : 100},
        { value : 'hover' , width : 10 , padding: 0},
      ]
    },
    statusSelect() {
      return [
        { text : this.$t('New') , value : 'new' },
        { text : this.$t('InProcess') , value : 'in_process' },
        { text : this.$t('Canceled') , value : 'canceled' },
        { text : this.$t('Paused') , value : 'paused' },
        { text : this.$t('Completed') , value : 'completed' }
      ]
    },
  },
  methods : {
    ...mapActions('project_manager', [
      'GET_USER_TASKS',
    ]),

    async initPage() {
      let result = await this.GET_USER_TASKS()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>