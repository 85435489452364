var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('Tasks')},model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}},[_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.items,"headers":_vm.headers,"dense":"","no-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('ws-check-box',{attrs:{"value":item.completed}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ws-text-field-new',{attrs:{"placeholder":_vm.$t('EnterName'),"color":_vm.wsACCENT,"bold":"","text-area":"","full-width":""},on:{"click":function($event){$event.preventDefault();},"change":function($event){return _vm.editComponent('name' , $event, item.uuid , _vm.i)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ft-select',{attrs:{"value":item.status,"items":_vm.statusSelect},on:{"input":function($event){return _vm.editComponent('status' , $event, item.uuid , _vm.i)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(text)+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)})]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(_vm.MONTH_DAY_TIME(item.date_created , false)))])]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_c('ws-date-picker',{key:item.uuid,attrs:{"value":item.date_start,"custom-activator":"","time-stamp":""},on:{"input":function($event){return _vm.editComponent('date_start' , $event, item.uuid , _vm.i)}}},[_c('h5',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.date_start ? _vm.MONTH_DAY_TIME(item.date_start , false) : _vm.$t('DateStart'))+" "),_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-menu-down")])],1)])]}},{key:"item.date_end",fn:function(ref){
var item = ref.item;
return [_c('ws-date-picker',{key:item.uuid,attrs:{"value":item.date_end,"custom-activator":"","time-stamp":""},on:{"input":function($event){return _vm.editComponent('date_end' , $event, item.uuid , _vm.i)}}},[_c('h5',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.date_end ? _vm.MONTH_DAY_TIME(item.date_end , false) : _vm.$t('Deadline'))+" "),_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-menu-down")])],1)])]}},{key:"item.hover",fn:function(ref){
var item = ref.item;
var hover = ref.hover;
return [_c('v-sheet',{attrs:{"color":"transparent","width":"30"}},[(hover)?_c('v-btn',{attrs:{"small":"","icon":"","color":_vm.wsACCENT},on:{"click":function($event){return _vm.deleteItem(item.uuid, _vm.i)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }